// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, useMediaQuery, Typography } from "@mui/material";
import { cloneElement, useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
// project imports
import LAYOUT_CONST from "constant";
import useConfig from "hooks/useConfig";
import LogoSection from "../LogoSection";
import SearchSection from "./SearchSection";
import MobileSection from "./MobileSection";
import ProfileSection from "./ProfileSection";
import LocalizationSection from "./LocalizationSection";
import MegaMenuSection from "./MegaMenuSection";
import NotificationSection from "./NotificationSection";

import { useDispatch, useSelector } from "store";
import { openDrawer } from "store/slices/menu";

// assets
import { IconMenu2 } from "@tabler/icons";

import Chip from "./Chip";
import Blink from "react-blink-text";
import { Link } from "@mui/material";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);
  const flag = useSelector((state) => state.flag.value);
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const { layout } = useConfig();
  const Usersid = window.localStorage.getItem("users");
  const { AllUsers, Statistics, Addfriend, MemberProfile } = useAuth();
  // const {id} = JSON.parse(Usersid);
  const val = JSON.parse(Usersid);
  const NId = val.id;
  const Space = " ";
  const [city, setcity] = useState([]);
  const [email, setemail] = useState([]);
  const [fname, setfname] = useState([]);
  const [lname, setlname] = useState([]);
  const [profileProgress, setprofileProgress] = useState([]);
  const [imgurl, setimgurl] = useState([]);
  const [occupation, setoccupation] = useState([]);
  const userInfoData = async (value) => {
    const list = await MemberProfile(NId, NId);
    setemail(list.data.data.profile_details[0].email);
    setcity(list.data.data.profile_details[0].city);
    setlname(list.data.data.profile_details[0].lname);
    setfname(list.data.data.profile_details[0].fname);
    setimgurl(list.data.data.profile_details[0].imgurl);
    setprofileProgress(list.data.data.profile_details[0].profileProgress);
    setoccupation(list.data.data.profile_details[0].occupation);
  };
  useEffect(() => {
    // getData();
    userInfoData();

    // userInfoData();
  }, [flag]);
  const fullname = fname + Space + lname;
  const Shortname = fullname.slice(0, 4) + "...";

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 45,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        {layout === LAYOUT_CONST.VERTICAL_LAYOUT ||
        (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              overflow: "hidden",
              transition: "all .2s ease-in-out",
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.dark.main
                  : theme.palette.secondary.light,
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.secondary.main
                  : theme.palette.secondary.dark,
              "&:hover": {
                background:
                  theme.palette.mode === "dark"
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.dark,
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.secondary.light
                    : theme.palette.secondary.light,
              },
            }}
            onClick={() => dispatch(openDrawer(!drawerOpen))}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="20px" />
          </Avatar>
        ) : null}
      </Box>
      <Box
        component="span"
        sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
      >
        <Typography component="span" variant="h2">
          Polaris
        </Typography>
      </Box>
      {/* header search */}
      {/* <SearchSection /> */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ marginRight: "15px", display: { xs: "none", sm: "block" } }}>
  <Typography component="span" variant="h3">
    <Blink
      color="red"
      text={
        profileProgress < 100 ? (
          <>
            {/* <Link underline="none" href="/profile/personal">
              <Chip
                label={`complete your profile details
                ${
                //   fname ? "" : " ,name"
                // }${
                //   city ? "" : " ,city"
                // }
                // ${
                  occupation ? "" : " ,add occupation"
                }${
                    imgurl === null || imgurl === "" || imgurl === "profileDefault.png"
                      ? " ,upload profile image"
                      : ""
                  }`}
                chipcolor="error"
                size="17px"
              />
            </Link> */}
          </>
        ) : (
          " "
        )
      }
      fontSize="20"
    />
  </Typography>
</Box>

      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <Typography component="span" variant="h3">
          <Blink
            color="red"
            text={
              profileProgress < 100 ? (
                <Link underline="none" href="/profile/personal">
                  <Chip
                    label={`Complete profile: ${Math.round(
                      profileProgress ?? 0
                    )}%`}
                    chipcolor="error"
                    size="17px"
                  />
                </Link>
              ) : (
                " "
              )
            }
            fontSize="20"
          />
        </Typography>
      </Box>

      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <h3>{fullname}</h3>
      </Box>

      <Box sx={{ display: { xs: "block", sm: "block" } }}>
        <NotificationSection />
      </Box>
      <Box sx={{ display: { xs: "block", sm: "block" } }}>
        <ProfileSection fullname={fullname} />
      </Box>

      {/* mobile header */}
      {/* <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box> */}
    </>
  );
};

export default Header;
