// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {IconReceipt2,IconSitemap } from '@tabler/icons';

// constant
const icons = {
    IconReceipt2,
    IconSitemap,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'others',
  
    title: <FormattedMessage id="others" />,
    type: 'group',
    children: [
        {
            id: 'Help Tickets',
            title: <FormattedMessage id="Help/Tickets" />,
            type: 'item',
            url: '/contact-us',
            icon: icons.IconReceipt2,
            breadcrumbs: false
        },
        {
            id: 'Help Response',
            title: <FormattedMessage id="Help Response" />,
            type: 'item',
            url: '/HelpResponse',
            icon: icons.IconSitemap,
            breadcrumbs: false
        }
    ]
};

export default other;
