// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics, IconUser,IconChecklist } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconUser,
    IconChecklist
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const Profile = {
    id: 'Profile',
    title: <FormattedMessage id="Profile" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'My Profile',
            title: <FormattedMessage id="My Profile" />,
            type: 'item',
            url: '/profile',
            icon: icons.IconUser,
            breadcrumbs: false
         }
       
    ]
    
};

export default Profile;
