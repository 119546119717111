import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Label from '@mui/icons-material/Label';
const Profile = Loadable(lazy(() => import('Collab-community-views/profile')));
const Follower = Loadable(lazy(() => import('Collab-community-views/FollowersPage')));
const CollabProfile = Loadable(lazy(() => import('Collab-community-views/profile')));
const PersonalDashboard=Loadable(lazy(()=>import('Collab-community-views/Personal-Dashboard')));
const CommunityDashboard=Loadable(lazy(()=>import('Collab-community-views/CommunityDashboard')));
const BasicHabitsIndex=Loadable(lazy(()=>import('Collab-community-views/My-Actions/Basic/Basic-Habits')));
const Eventsdetsails=Loadable(lazy(()=>import('Collab-community-views/My-Actions/Event')));
const User_Management=Loadable(lazy(()=>import('Collab-community-views/Adminviews/User_management')));
const Absent_report = Loadable(lazy(() => import('Collab-community-views/Adminviews/Absent_management')));
const UtilityReport=Loadable(lazy(()=>import('Collab-community-views/Adminviews/Trace')));
const Event_management=Loadable(lazy(()=>import('Collab-community-views/Adminviews/Event_management')));
const Help_Management=Loadable(lazy(()=>import('Collab-community-views/Adminviews/Help_management')));
const Others_Help_contactus=Loadable(lazy(()=>import('Collab-community-views/Others/contact-us')));
const Others_HelpResponse=Loadable(lazy(()=>import('Collab-community-views/Others/HelpResponse')));
const BasicQuizIndex=Loadable(lazy(()=>import('Collab-community-views/My-Actions/Basic/Basic-Quiz')));
const Learning = Loadable(lazy(() => import('Collab-community-views/Resources/LearningPath/index.js')));
const ReferencePage = Loadable(lazy(()=>import('Collab-community-views/Resources/Reference/ReferencePage')));
//Advance quiz
const AdvanceHabit=Loadable(lazy(()=>import('Collab-community-views/My-Actions/Advance/Advance-Habits')));
const AdvanceQuiz = Loadable(lazy(()=>import('Collab-community-views/My-Actions/Advance/Advance-Quiz/index.js')));
const PerformaceChecker=Loadable(lazy(()=>import('Collab-community-views/My-Actions/Advance/PerformanceChecker')));

//Advance quiz
const Bookopedia = Loadable(lazy(()=>import('Collab-community-views/My-Actions/StockoPediaNew/index.js')))
const ReportDashboard=Loadable(lazy(()=>import('Collab-community-views/Adminviews/ReportDashboard')));
const AddBook =Loadable(lazy(()=>import('Collab-community-views/Adminviews/BookopediaManagement/ADD/AddBook.js')));
const EditBook =Loadable(lazy(()=>import('Collab-community-views/Adminviews/BookopediaManagement/EDIT/EditBook.js')));
const DiamondChecklist = Loadable(lazy(()=>import('Collab-community-views/My-Actions/Advance/Business_Ranking_Calculator/Quiz.js')));
const Create_Batch = Loadable(lazy(()=>import('Collab-community-views/Adminviews/Batch_Management/index.js')))
const GroupManagement=Loadable(lazy(()=>import('Collab-community-views/Adminviews/User_management/EditGroupDetails')));

const LeaveApplication = Loadable(lazy(()=>import('Collab-community-views/My-Actions/LeaveManagement/LeaveApplication')));
const LeaveApplicationResponse = Loadable(lazy(()=>import('Collab-community-views/My-Actions/LeaveManagement/LeaveResponse')));
const LeaveManagement=Loadable(lazy(()=>import('Collab-community-views/Adminviews/Leave_management')));

const DiamondBasicReport = Loadable(lazy(()=>import('Collab-community-views/Adminviews/Diamond_Report/Diamond_basic_report/index.js')));
const DiamondHabiteport = Loadable(lazy(()=>import('Collab-community-views/Adminviews/Diamond_Report/Diamond_habit_report/index.js')));
const DiamondCheckListReport = Loadable(lazy(()=>import('Collab-community-views/Adminviews/Diamond_Report/DiamondChecklist_Report/index.js')))
const WeeklyReport = Loadable(lazy(()=>import('Collab-community-views/Adminviews/Absent_management/WeeklyReport/WeeklyReport.js')));
const MonthlyReport = Loadable(lazy(()=>import('Collab-community-views/Adminviews/Absent_management/MonthlyReport/MonthlyReport.js')));

const UserCountReport = Loadable(lazy(()=>import('Collab-community-views/Adminviews/UserCount_Report/index.js')));


const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
       
        {
            path: '/Profile',
            element: <Profile />
        },
        {
            path: '/profile/:tab',
            element: <CollabProfile />
        },
        {
            path: '/followerspage/:tab/:Friendid',
            element: <Follower />
        },
        {
            path:'/Personal-Dashboard',
            element:<PersonalDashboard/>
        },
        {
            path:'/CommunityDashboard',
            element:<CommunityDashboard/>
        },
        {
            path:'/BasicHabits',
            element:<BasicHabitsIndex/>
        },
        {
            path:'/AdvancedHabits',
            element:<AdvanceHabit/>
        },
        {
            path:'/Events',
            element:<Eventsdetsails/>
        },
        {
            path:'/User_Management',
            element:<User_Management/>
        },
        {
            path:'/absent_report',
            element:<Absent_report/>
        },
        {
            path:'/UtilityReport',
            element:<UtilityReport/>
        },
        {
            path:'/Event_Management',
            element:<Event_management/>
        },
        {
            path:'/Help_Management',
            element:<Help_Management/>
        },
        {
            path:'/contact-us',
            element:<Others_Help_contactus/>
        },
        {
            path:'/HelpResponse',
            element:<Others_HelpResponse/>
        },
        {
            path:'/CBLTracker',
            element:<BasicQuizIndex/>
        },
        {
            path: '/Learning',
            element: <Learning />
        },
        {
            path: '/Reference',
            element: <ReferencePage />
        },
        {
            path: '/AdvancedQuiz',
            element: <AdvanceQuiz/>
        },
        {
            path:'/dailyPerformance',
            element:<PerformaceChecker/>
        },
        {
            path: '/Bookopedia',
            element: <Bookopedia/>
        },
        {
            path:'/ReportDashboard',
            element:<ReportDashboard/>
        },
        {
            path:'/AddBook',
            element:<AddBook/>
        },
        {
            path:'/EditBook',
            element:<EditBook/>
        },
        {
            path:'/DiamondChecklist',
            element:<DiamondChecklist/>
        },
        {
            path:"/Createbatch",
            element:<Create_Batch/>
        },
        {
            path:'/GroupManagement',
            element:<GroupManagement/>
        },
        {
            path:'/LeaveApplication',
            element:<LeaveApplication/>
        },
        {
            path:'/LeaveApplicationResponse',
            element:<LeaveApplicationResponse/>
        },
        {
            path:'/LeaveManagement',
            element:<LeaveManagement/>
        },
        {
            path:'/Diamond_basic_report',
            element:<DiamondBasicReport/>
        },
        {
            path:'/Diamond_habit_report',
            element:<DiamondHabiteport/>
        },
        {
            path:'/WeeklyReport',
            element:<WeeklyReport/>
        },
        {
            path:'/MonthlyReport',
            element:<MonthlyReport/>
        },
        {
            path:'/ChecklistReport',
            element:<DiamondCheckListReport/>
        },
        {
            path:'/UserCountReport',
            element:<UserCountReport/>
        }
    ]
};

export default MainRoutes;
