import React from 'react';
import { Avatar,Box,Typography,Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import indianFlage from './assets/Flag-India.webp';



const avatarStyle = {
   width: '15px',
   height: '15px',
 };
 function Footer(){
    
    
    return (
        <Grid marginTop="10px" >
            <Box >
               
              <Typography sx={{fontSize:'12px'}} >Developed by <Link to="https://www.nuvolance.com/" target="_blank" style={{ textDecoration: 'none', color: 'blue' }} >  Nuvolance </Link>  &copy; 2022-{(new Date()).getFullYear()}
              </Typography>  
              <Typography  sx={{display:'flex',marginTop:'2px', fontSize:'12px'}}>Made in &nbsp;<Avatar src={indianFlage} style={avatarStyle}></Avatar>
              
               </Typography>
              
              </Box>
        </Grid>
    )
 }

 export default Footer;