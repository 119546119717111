// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconDashboard,
  IconDeviceAnalytics,
  IconClipboardCheck,
  IconPictureInPicture,
  IconForms,
  IconChartArcs,
  IconDatabaseExport,
  IconApps,
  IconUsers,
  IconCalendarEvent,
  IconUserMinus
} from "@tabler/icons";
import GroupsIcon from "@mui/icons-material/Groups";
import Book from "@mui/icons-material/Book";
import DescriptionIcon from '@mui/icons-material/Description';
import GradingIcon from '@mui/icons-material/Grading';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventNoteIcon from '@mui/icons-material/EventNote';

// constant
const icons = {
  IconDashboard,
  IconDeviceAnalytics,
  IconClipboardCheck,
  IconPictureInPicture,
  IconForms,
  IconChartArcs,
  IconDatabaseExport,
  IconApps,
  IconUsers,
  IconCalendarEvent,
  IconUserMinus
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const Usersid = window.localStorage.getItem("users");
const userData = Usersid ? JSON.parse(Usersid) : {};
const { role_id } = userData;
const Admin = {
  id: "Admin Dashboard",
  title: <FormattedMessage id="Admin Dashboard" />,
  icon: icons.IconDashboard,
  type: "group",
  children:
    role_id === 2
      ? [
        {
          id: "Group Report",
          title: <FormattedMessage id="Group Report" />,
          type: "item",
          url: "/ReportDashboard",
          icon: GroupsIcon,
          breadcrumbs: false,
        },
      ]
      : [
        {
          id: "Administration",
          title: <FormattedMessage id="Administration" />,
          type: "collapse",
          // url: "",
          icon: icons.IconApps,
          breadcrumbs: false,
          children: [
            {
              id: "Batch",
              title: <FormattedMessage id="Batch" />,
              type: "item",
              url: "/Createbatch",
              icon: icons.IconApps,
              breadcrumbs: false,
            },
            {
              id: "Groups",
              title: <FormattedMessage id="Groups" />,
              type: "item",
              url: "/GroupManagement",
              icon: icons.IconApps,
              breadcrumbs: false,
            },
            {
              id: "Users",
              title: <FormattedMessage id="Users" />,
              type: "item",
              url: "/User_Management",
              icon: icons.IconForms,
              breadcrumbs: false,
            },
          ]
        },
        // {
        //   id: "Report",
        //   title: <FormattedMessage id="Report" />,
        //   type: "item",
        //   url: "/UtilityReport",
        //   icon: icons.IconForms,
        //   breadcrumbs: false,
        // },
        {
          id: "Reports",
          title: <FormattedMessage id="Reports" />,
          type: "collapse",
          icon: DescriptionIcon,
          breadcrumbs: false,
          children: [
            {
              id: "Absent report",
              title: <FormattedMessage id="Daily Report" />,
              type: "item",
              url: "/absent_report",
              icon: ListAltIcon,
              breadcrumbs: false,
            },
            {
              id: "Weekly report",
              title: <FormattedMessage id="Weekly Report" />,
              type: "item",
              url: "/WeeklyReport",
              icon: DateRangeIcon,
              breadcrumbs: false,
            },
            {
              id: "Monthly report",
              title: <FormattedMessage id="Monthly Report" />,
              type: "item",
              url: "/MonthlyReport",
              icon: CalendarMonthIcon,
              breadcrumbs: false,
            },]
        },
        {
          id: "Diamond Reports",
          title: <FormattedMessage id="Diamond Reports" />,
          type: "collapse",
          // url: "",
          icon: DescriptionIcon,
          breadcrumbs: false,
          children: [
            {
              id: " Basic Report",
              title: <FormattedMessage id=" Basic Report" />,
              type: "item",
              url: "/Diamond_basic_report",
              icon: FileCopyIcon,
              breadcrumbs: false,
            },
            {
              id: "Habit Report",
              title: <FormattedMessage id=" Habit Report" />,
              type: "item",
              url: "/Diamond_habit_report",
              icon: GradingIcon,
              breadcrumbs: false,
            },
            {
              id: "ChecklistReport",
              title: <FormattedMessage id=" CheckList Report" />,
              type: "item",
              url: "/ChecklistReport",
              icon: LocalLibraryIcon,
              breadcrumbs: false,
            },
          ]
        },
        {
          id: "Group Report",
          title: <FormattedMessage id="Group Report" />,
          type: "item",
          url: "/ReportDashboard",
          icon: GroupsIcon,
          breadcrumbs: false,
        },
        {
          id: "User Counts",
          title: <FormattedMessage id="User Counts" />,
          type: "item",
          url: "/UserCountReport",
          icon: EventNoteIcon,
          breadcrumbs: false,
        },
        {
          id: "Event Management",
          title: <FormattedMessage id="Event Management" />,
          type: "item",
          url: "/Event_Management",
          icon: icons.IconPictureInPicture,
          breadcrumbs: false,
        },
        {
          id: "Help Management",
          title: <FormattedMessage id="Help Management" />,
          type: "item",
          url: "/Help_Management",
          icon: icons.IconClipboardCheck,
          breadcrumbs: false,
        },
        {
          id: "Leave Management",
          title: <FormattedMessage id="Leave Management" />,
          type: "item",
          url: "/LeaveManagement",
          icon: icons.IconClipboardCheck,
          breadcrumbs: false,
        },
        // {
        //   id: "Bookopedia Management",
        //   title: <FormattedMessage id="Bookopedia Management" />,
        //   type: "item",
        //   url: "/BookopediaManagement",
        //   icon: Book,
        //   breadcrumbs: false,
        // },
        {
          id: "Bookopedia Management",
          title: <FormattedMessage id="Bookopedia" />,
          type: "collapse",
          icon: Book,
          breadcrumbs: false,
          children: [
            {
              id: "AddBook",
              title: <FormattedMessage id="Add Book - Category" />,
              type: "item",
              url: "/AddBook",
              icon: icons.IconApps,
              breadcrumbs: false,
            },
            {
              id: "EditBook",
              title: <FormattedMessage id="Edit Book" />,
              type: "item",
              url: "/EditBook",
              icon: icons.IconApps,
              breadcrumbs: false,
            },
          ]
        },
      ],
};

export default Admin;
