import dashboard from './dashboard';
import MyActions from './MyActions';
import Help from './Help';
import Profile from './Profile';
import Others from './other';
import Admin from './Admin';
import Resource from './Resource';
// import Modules from './Modules';
// ==============================|| MENU ITEMS ||============================== //
const Usersid = window.localStorage.getItem("users");

// const Suserid = useSelector((state)=>state.counter.value);

let menuItems={};
menuItems = {
    items: [Profile,dashboard,MyActions,Resource,Help]
};
if(Usersid){
   
    const { role_id } = JSON.parse(Usersid);
    if(role_id!==1){
        
        menuItems = {
           items: [Profile,dashboard,MyActions,Resource,Help]
       };
   }
   if(role_id===1){
 
    menuItems = {
       items: [Profile,dashboard,MyActions,Resource,Admin,Help]
   };
   }
   if(role_id===2){
 
    menuItems = {
       items: [Profile,dashboard,MyActions,Resource,Admin,Help]
   };
   }
   
}

export default menuItems;

