import { createSlice } from '@reduxjs/toolkit'

export const flagSlice = createSlice({
  name: 'flag',
  initialState: {
    value: true
  },
  reducers: {
   
    Reload: (state, action) => {
      console.log("indise",action.payload);
      state.value = action.payload
    }
  }
})

export const { Reload } = flagSlice.actions

export default flagSlice.reducer