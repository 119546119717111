import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";

// third-party
import { Chance } from "chance";
import jwtDecode from "jwt-decode";

// reducer - state management
import { LOGIN, LOGOUT } from "store/actions";
import accountReducer from "store/accountReducer";

// project imports
import Loader from "ui-component/Loader";
import axios from "utils/axios";
import Axios from "axios";
import { API_URL, APP_URL } from "config";

const SERVER_API_URL = API_URL;
const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }

  const decoded = jwtDecode(serviceToken);

  /**
   * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem("serviceToken");
    localStorage.removeItem("users");
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem("serviceToken");
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          const response = await axios.get("/api/account/me");
          const { user } = response.data;
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user,
            },
          });
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
  }, []);

  const login = async (email, password, RememberMe) => {
    if (RememberMe === true) {
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
    }
    if (RememberMe === false) {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
    const response = await Axios.post(SERVER_API_URL + "/api/auth/signin", {
      email,
      password,
    });

    if (response.data.status !== "200") {
      return response.data;
    } else if (response.data.status === "200") {
      const serviceToken = response.data.data.accesstoken;

      setSession(serviceToken);
      let user = {
        id: response.data.data.id,
        email: response.data.data.email,
        name: response.data.data.email,
        groupId: response.data.data.groupId,
        role_id: response.data.data.role_id,
        subscriptionType: response.data.data.subscriptionType,
      };
      window.localStorage.setItem("users", JSON.stringify(user));
      window.location.href = "/Personal-Dashboard";

      return response.data;
    }
  };

  const delay = (timeout) => new Promise((res) => setTimeout(res, timeout));

  const register = async (email, password, firstName, lastName, mobile) => {
    try {
      await delay(500);

      if (!email || !password) {
        return [400, { message: "Enter Your Email & Password" }];
      }

      if (!firstName || !lastName) {
        return [400, { message: "Enter Your Name" }];
      }

      const id = chance.bb_pin();

      const response = await Axios.post(SERVER_API_URL + "/api/auth/signup", {
        id,
        email,
        password,
        firstName,
        lastName,
        mobile,
      });

      return response.data;
    } catch (err) {
      return [500, { message: "Server Error" }];
    }
  };

  const registerOtpVerify = async (regUserId, otp) => {
    const id = chance.bb_pin();
    const response = await Axios.post(SERVER_API_URL + "/api/account/registerotpverify", {
      id,
      regUserId,
      otp,
    });

    return response.data;
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async (email) => {
    try {
      const ServerResponseNext = await Axios.post(
        SERVER_API_URL + "/api/account/forgot",
        { email }
      );

      let userRes = [];
      if (
        ServerResponseNext.data.status &&
        ServerResponseNext.data.status === "200"
      ) {
        userRes = [200, ServerResponseNext.data.data];
      } else {
        userRes = [201, ServerResponseNext.data];
      }
      return userRes;
    } catch (err) {
      console.error(err);
      return [500, { message: "Server Error" }];
    }
  };

  const verifyotp = async (UserId, otp) => {
    try {
      const ServerResponseNext = await Axios.post(
        SERVER_API_URL + "/api/account/forgot/verifyotp",
        { UserId, otp }
      );

      let userRes = [];
      if (
        ServerResponseNext.data.status &&
        ServerResponseNext.data.status === "200"
      ) {
        userRes = [200, ServerResponseNext.data];
      } else {
        userRes = [201, ServerResponseNext.data];
      }
      return userRes;
    } catch (err) {
      console.error(err);
      return [500, { message: "Server Error" }];
    }
  };

  const newPassword = async (UserId, Password) => {
    try {
      const ServerResponseNext = await Axios.post(
        SERVER_API_URL + "/api/auth/reset_password",
        { UserId, Password }
      );

      let userRes = [];
      if (
        ServerResponseNext.data.status &&
        ServerResponseNext.data.status === "200"
      ) {
        userRes = [200, ServerResponseNext.data];
      } else {
        userRes = [201, ServerResponseNext.data];
      }
      return userRes;
    } catch (err) {
      console.error(err);
      return [500, { message: "Server Error" }];
    }
  };
  const Profiledata = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/profile_view", { userId });
    verifyAuthorization(response);
    return response.data;
  };

  const SociallinkView = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/Sociallink_view", { userId });
    verifyAuthorization(response);
    return response;
  };
  const updateProfile = async (
    fname,
    lname,
    city,
    tradingviewIid,
    address,
    occupation,
    userId
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/profile_update", {
      fname,
      lname,
      city,
      tradingviewIid,
      address,
      occupation,
      userId,
    });
    verifyAuthorization(response);
    return response.data;
  };

  const updateSociallink = async (
    about,
    instaLink,
    fbLink,
    twitterLink,
    linkedinLink,
    userId
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/Sociallinkupdate", {
      about,
      instaLink,
      fbLink,
      twitterLink,
      linkedinLink,
      userId,
    });
    verifyAuthorization(response);
    return response.data;
  };

  const AllUsers = async (
    userId,
    search,
    GroupFilterstate,
    searchfilterstate
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    // alert(searchfilterstate + 'i am jwt');
    const response = await Axios.post(SERVER_API_URL + "/api/user/AllUsers", {
      userId,
      search,
      GroupFilterstate,
      searchfilterstate,
    });
    verifyAuthorization(response);
    return response.data;
  };

  const Addfriend = async (userId, followUserId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/follow_member", {
      userId,
      followUserId,
    });
    verifyAuthorization(response);
    return response.data;
  };
  const Removefriend = async (userId, followUserId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/unfollow_member", {
      userId,
      followUserId,
    });
    verifyAuthorization(response);
    return response.data;
  };
  const MyFollower = async (userId, search, followUserId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/followers_list", {
      userId,
      search,
    });
    verifyAuthorization(response);
    return response;
  };

  const MyFriends = async (userId, search, followUserId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/following_list", {
      userId,
      search,
    });
    verifyAuthorization(response);
    return response;
  };

  const MyGrouplist = async (userId, groupId, search) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/mygroup_members_list", {
      userId,
      groupId,
      search,
    });
    verifyAuthorization(response);
    return response;
  };

  const Statistics = async (userId, memberId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/members_statistics", {
      userId,
      memberId,
    });
    verifyAuthorization(response);
    return response;
  };
  const MemberProfile = async (userId, memberId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/view_member_profile", {
      userId,
      memberId,
    });
    verifyAuthorization(response);
    return response;
  };

  function verifyAuthorization(response) {
    console.log(response.data.statuscode);
    if (response.data.statuscode === 401) {

      // ---------for testing--------------//


      // Axios.post(server url + "/url",{userId})

      logout();
      window.location.href = APP_URL + "/login";
      // -------------//-------------------//
    } else {
      return true;
    }
  }
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }
  const GetHabitsList = async (userId, user_id, curmonth, subscriptionType) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/GetHabitsList",
      { userId, user_id, curmonth, subscriptionType }
    );
    return response;
  };

  const InsertHabit = async (
    userId,
    user_id,
    habit_id,
    habitName,
    date,
    week,
    month,
    Day,
    Year
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/InsertHabit",
      { userId, user_id, habit_id, habitName, date, week, month, Day, Year }
    );
    return response;
  };

  const UpdateHabit = async (userId, updatedValues) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/UpdateHabit",
      { userId, updatedValues }
    );
    return response;
  };

  const Gethabitsdata = async (userId, user_id) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/Gethabitsdata",
      { userId, user_id }
    );
    return response;
  };
  const handleChangedeletedata = async (
    userId,
    user_id,
    habit_id,
    date,
    week,
    month,
    Day,
    Year
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/Habitdeletedata",
      { userId, user_id, habit_id, date, week, month, Day, Year }
    );
    return response;
  };

  const habit_chart = async (userId, user_id) => {
    const response = await Axios.post(SERVER_API_URL + "/api/habit_chart", {
      userId,
      user_id,
    });
    return response;
  };

  const insert_new_habit = async (userId, user_id, habitName) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/insert_new_habit",
      { userId, user_id, habitName }
    );
    return response;
  };

  const Delete_habit = async (userId, user_id, habitName, habit_id) => {
    const response = await Axios.post(SERVER_API_URL + "/api/delete_habit", {
      userId,
      user_id,
      habitName,
      habit_id,
    });
    return response;
  };

  const Pastevent = async (userId, subscriptionType) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/event/Pastevent", {
      userId,
      subscriptionType,
    });
    verifyAuthorization(response);
    return response;
  };
  const Futurevent = async (userId, subscriptionType) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/Futurevent",
      { userId, subscriptionType }
    );
    verifyAuthorization(response);
    return response;
  };
  const Todayevent = async (userId, Eventuser_id, subscriptionType) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/Todayevent",
      { userId, Eventuser_id, subscriptionType }
    );
    verifyAuthorization(response);
    return response;
  };

  const UserDetails = async (userId, search, usettypes, Selectuser, BatchValue) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/userdetail",
      { userId, search, usettypes, Selectuser, BatchValue }
    );
    verifyAuthorization(response);
    return response;
  };

  const Editdatauser = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/Editdatauser",
      { userId, value }
    );
    verifyAuthorization(response);
    return response;
  };
  const EditSavedata = async (
    userId,
    userID,
    fnamedata,
    lastnamdata,
    emaildata,
    Phonenumber,
    CountryDialCode,
    UserTypes,
    EditAssignedgroup,
    StartDate,
    EndDate,
    Statusdata,
    Moderator,
    grpupId
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/Editsavedata",
      {
        userId,
        userID,
        fnamedata,
        lastnamdata,
        emaildata,
        Phonenumber,
        CountryDialCode,
        UserTypes,
        EditAssignedgroup,
        StartDate,
        EndDate,
        Statusdata,
        Moderator,
        grpupId
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const passwordReset = async (
    userId, userID, fname, lname
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/passwordreset",
      {
        userId,
        userID,
        fname,
        lname,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const Adddatauser = async (
    userId,
    Addfnamedata,
    Addlnamedata,
    Addemaildata,
    Addmobilenumberdata,
    AddCountryDialCode,
    AddCitydata,
    Addaddressdata,
    AddSubscriptionstartdata,
    // AddSubscriptionenddataformet,
    AddUserType,
    values
    // mailedDate
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/Adddatauser",
      {
        userId,
        Addfnamedata,
        Addlnamedata,
        Addemaildata,
        Addmobilenumberdata,
        AddCountryDialCode,
        AddCitydata,
        Addaddressdata,
        AddSubscriptionstartdata,
        // AddSubscriptionenddataformet,
        AddUserType,
        // mailedDate
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const CoreAuditReportdata = async (value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/CoreAuditReport",
      { value }
    );
    return response;
  };

  const QuantumQuizdata = async (value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/QuantumQuiz",
      { value }
    );
    return response;
  };
  const NotloginReportdata = async (value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/NotLoginReport",
      { value }
    );
    return response;
  };

  const Csvreport = async (userId, module, date) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/Csvreport",
      { userId, module, date }
    );
    return response;
  };

  const CharityReport = async (value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/CharityReport",
      { value }
    );
    return response;
  };
  const Watchlist = async (value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/SatelliteWatchListReport",
      { value }
    );
    return response;
  };

  const LoginReport = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/LoginReport",
      { userId, value }
    );
    return response;
  };

  const AlertReport = async (value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/AlertReport",
      { value }
    );
    return response;
  };
  const EventReport = async (value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/EventlatestReport",
      { value }
    );
    return response;
  };

  const GroupnameList = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/GroupnameList",
      { userId }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const AddEvent = async (
    eventId,
    eventStart,
    eventEnd,
    userId,
    subscriptionType,
    Description,
    EventName,
    Link,
    Recurrencevalue,
    occurrences,
    weekarr,
    eid
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/event/AddEvent", {
      eventId,
      eventStart,
      eventEnd,
      userId,
      subscriptionType,
      Description,
      EventName,
      Link,
      Recurrencevalue,
      occurrences,
      weekarr,
      eid,
    });
    verifyAuthorization(response);
    return response.data;
  };

  const GetEventsList = async (userId, search, FilterGroup, timeline) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/GetEventsList",
      { userId, search, FilterGroup, timeline }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const DeleteSchedule = async (userId, Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/DeleteSchedule",
      { userId, Id }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const DeleteAllSchedule = async (userId, EventId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/DeleteAllSchedule",
      { userId, EventId }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const updateEvent = async (
    eventId,
    eventEnd,
    Description,
    Link,
    userId,
    eventStart,
    subscriptionType,
    EId,
    Name
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/updateEvent",
      {
        eventId,
        eventEnd,
        Description,
        Link,
        userId,
        eventStart,
        subscriptionType,
        EId,
        Name,
      }
    );
    verifyAuthorization(response);
    return response.data;
  };

  // - - - - - - - - - - - - - Help Management - - - - - - - - -

  const GetdataHelpManagement = async (userId, filter, search, category) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/contact/GetContactData",
      { userId, filter, search, category }
    );
    verifyAuthorization(response);
    return response;
  };

  const GetcategorylistTool = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/getcategorylistTool",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const UpdateResponse = async (message, PrimaryId, status, userIds, email, userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/UpdateResponse", {
      message,
      PrimaryId,
      status,
      userIds,
      email,
      userId,
    });
    verifyAuthorization(response);
    return response;
  };

  const GetcategoryWithoutLogin = async () => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/getcategorylistWithoutlogin",
      {}
    );
    verifyAuthorization(response);
    return response;
  };
  const InsertWithLogin = async (
    userId,
    category,
    email,
    mobile,
    subject,
    content,
    fileName
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;

    const response = await Axios.post(SERVER_API_URL + "/api/contactInsert", {
      userId,
      category,
      email,
      mobile,
      subject,
      content,
      fileName,
    });

    verifyAuthorization(response);
    return response;
  };
  const ContactInsert = async (
    userId,
    category,
    email,
    mobile,
    subject,
    content,
    files,
    ext
  ) => {
    const data = new FormData();
    let response = "";
    let fileName = "";
    if (files.length !== 0) {
      data.append("file", files[0]);
      const ext = files[0].name.substring(files[0].name.lastIndexOf(".") + 1);

      if (ext === "pdf" || ext === "png" || ext === "jpg" || ext === "jpeg") {
        const upload = await Axios.post(
          SERVER_API_URL + "/uploadAttachment",
          data
        ).then((upload) => {
          fileName = upload.data[0].filename;
          response = InsertWithLogin(
            userId,
            category,
            email,
            mobile,
            subject,
            content,
            fileName
          );
        });
      }
      return response;
    } else {
      response = InsertWithLogin(
        userId,
        category,
        email,
        mobile,
        subject,
        content,
        fileName
      );
      return response;
    }
  };

  const ContactInsertWithoutLogin = async (
    userId,
    category,
    email,
    mobile,
    subject,
    content,
    files
  ) => {
    const data = new FormData();
    let response = "";
    let fileName = "";
    if (files.length !== 0) {
      data.append("file", files[0]);
      const ext = files[0].name.substring(files[0].name.lastIndexOf(".") + 1);
      if (ext === "pdf" || ext === "png" || ext === "jpg" || ext === "jpeg") {
        const upload = await Axios.post(
          SERVER_API_URL + "/uploadAttachment",
          data
        ).then((upload) => {
          fileName = upload.data[0].filename;
          response = Insert(
            userId,
            category,
            email,
            mobile,
            subject,
            content,
            fileName
          );
        });
      }
      return response;
    } else {
      response = Insert(
        userId,
        category,
        email,
        mobile,
        subject,
        content,
        fileName
      );

      return response;
    }
  };

  const Insert = async (
    userId,
    category,
    email,
    mobile,
    subject,
    content,
    fileName
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/contactInsertWithoutlogin",
      { userId, category, email, mobile, subject, content, fileName }
    );

    return response;
  };

  const Displayresponse = async (userId, userid) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/Displayresponse", {
      userId, userid,
    });
    verifyAuthorization(response);
    return response;
  };

  const TopTwentyuserDetailsBaseOnRewaerdPoint = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/TopTwentyuserDetailsBaseOnRewaerdPoint",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  //LEARNING PATH
  const GetLearing = async (userId, topicType) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/GetLearing", {
      userId,
      topicType,
    });
    verifyAuthorization(response);
    return response;
  };

  const DeleteLearningHistory = async (userId, userid, topicid) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/LearnPath/DeleteLearningHistory",
      { userId, userid, topicid }
    );
    verifyAuthorization(response);
    return response;
  };

  const InsertLearningHistory = async (userId, userid, topicid) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/InsertLearningHistory",
      { userId, userid, topicid }
    );
    verifyAuthorization(response);
    return response;
  };

  const CheckTotalCountOfLearning = async (userId, topicType) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/LearnPath/CheckTotalCountOfLearning",
      { userId, topicType }
    );
    verifyAuthorization(response);
    return response;
  };

  const CheckStatusOfLearning = async (userId, userid, topicType) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/CheckStatusOfLearning",
      { userId, userid, topicType }
    );
    verifyAuthorization(response);
    return response;
  };
  //LEARNING PATH

  //REFERENCE PAGE
  const Resourceslist = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/Resources", { userId });
    verifyAuthorization(response);
    return response;
  };
  const ResearchList = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/Research", { userId });
    verifyAuthorization(response);
    return response;
  };

  const TemplateList = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/Templates", { userId });
    verifyAuthorization(response);
    return response;
  };

  const TechnoList = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/Techno", { userId });
    verifyAuthorization(response);
    return response;
  };
  //REFERENCE PAGE

  //QUIZ
  const Quiz_Question = async (subscriptionType, userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/Quiz", {
      subscriptionType,
      userId,
    });
    verifyAuthorization(response);
    return response;
  };

  const insert_Quiz = async (userId, answer) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/answer", {
      userId, answer,
    });
    verifyAuthorization(response);
    return response;
  };

  const Check_user = async (userId, userid) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user", { userId, userid });
    verifyAuthorization(response);
    return response;
  };
  //QUIZ

  //CBL
  const DailyCblDownload = async (userId, batchId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/CBLDownload",
      { userId, batchId }
    );
    verifyAuthorization(response);
    return response;
  };
  //CBL

  const Stockopedia_post = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/stockopediaPost", { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const Stockopedia_company = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/stockopediaCompany", { userId }
    );
    verifyAuthorization(response);
    // console.log("ressss",response);
    return response;
  };

  const readingcomprehension = async (userId, user_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/reading.controller/readingcomprehension",
      { userId, user_id }
    );
    verifyAuthorization(response);
    return response;
  };

  const readingspeed = async (userId, user_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/reading.router/readingspeed",
      { userId, user_id }
    );
    verifyAuthorization(response);
    return response;
  };

  const diamondDashboard = async (userId, user_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/reading.router/diamonddashboard",
      { userId, user_id }
    );
    verifyAuthorization(response);
    return response;
  };

  const Groupuserdetails = async (fillterusertype, searchUserdata) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/groupdetails",
      { fillterusertype, searchUserdata }
    );

    verifyAuthorization(response);
    return response;
  };

  const GetBatch = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/Batch/GetBatch", { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const Alloccupationlist = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/Profile/Alloccupationlist", { userId });
    verifyAuthorization(response);
    return response;
  };
  // const Getgrouprewardschartdata = async () => {
  //     const serviceToken = window.localStorage.getItem("serviceToken");
  //     Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  //     const response = await Axios.post(SERVER_API_URL+"/api/grouprewards/chartdata", {});
  //   verifyAuthorization(response);
  //   console.log("Response data from Getgrouprewardschartdata:", response.data);
  //     return response;
  //   };
  const Getgrouprewardschartdata = async (userId, criteria) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    // const payload = { criteria: criteria };
    const response = await Axios.post(
      SERVER_API_URL + "/api/grouprewards/chartdata",
      { userId, criteria }
    );
    verifyAuthorization(response);
    return response;
  };

  const usersreport = async (userId, speedFilter, comprehensionFilter, searchQuery, batchId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;

    // Create an object to send as the request body with the filters
    // const requestData = {
    //   userId,
    //   readingComprehensionRange: comprehensionFilter,
    //   readingSpeedRange: speedFilter,
    //   searchQuery: searchQuery,
    //   batchId: batchId
    // };

    const response = await Axios.post(
      SERVER_API_URL + "/api/data/usersreport",
      {
        userId,
        readingSpeedRange: speedFilter,
        readingComprehensionRange: comprehensionFilter,
        searchQuery: searchQuery,
        batchId: batchId
      }
    );
    verifyAuthorization(response);
    return response;
  };

  // Absent report Apis
  const lowAttendanceReport = async (userId, subscriptionTypeFilter, batchValue, monthFilter, selectedCblday, Categories, search) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;

    // Create an object to send as the request body with the filters
    const requestData = {
      userId,
      lowAttendanceSubFilter: subscriptionTypeFilter,
      lowAttendanceBatchFilter: batchValue,
      lowAttendanceMonthFilter: monthFilter,
      lowAttendanceCBLDayFilter: selectedCblday,
      CategoriesFilter: Categories,
      search: search
    };

    const response = await Axios.post(
      SERVER_API_URL + "/api/data/lowattendancereport",
      requestData
    );
    verifyAuthorization(response);
    return response;
  };

  const megaChallengeAbsenteesReport = async (userId, subscriptionTypeFilter, batchValue, monthFilter) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;

    // Create an object to send as the request body with the filters
    const requestData = {
      userId,
      megaChallengeSubFilter: subscriptionTypeFilter,
      megaChallengeBatchFilter: batchValue,
    };

    const response = await Axios.post(
      SERVER_API_URL + "/api/data/megachallengeabsenteesreport",
      requestData
    );
    verifyAuthorization(response);
    return response;
  };

  // Absent report Apis

  //DIAMOND RITUAL TRACKERS
  const Ritual_Questions = async (userId, user_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/questions", {
      userId,
      user_id,
    });
    verifyAuthorization(response);
    return response;
  };

  const Insert_Rituals = async (userId, user_id, question_id, stage, answer) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/insert_rituals",
      { userId, user_id, question_id, stage, answer }
    );
    verifyAuthorization(response);
    return response;
  };

  const Update_stage6 = async (userId, updatedValues) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/Update_stage",
      { userId, updatedValues }
    );
    verifyAuthorization(response);
    return response;
  };

  const Check_stage = async (userId, user_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/Checkstage", {
      userId,
      user_id,
    });
    verifyAuthorization(response);
    return response;
  };

  const total_points = async (userId, user_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/points", {
      userId,
      user_id,
    });
    verifyAuthorization(response);
    return response;
  };

  const ReadingSpeedTop5 = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/readingspeedtop5", { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const CountofMegas = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/countofmegas", { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const getMyGroupCount = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/getMyGroupCount", {
      userId,
    });
    verifyAuthorization(response);
    return response;
  };
  const basicQuizmarks = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/basicQuizmarks", {
      userId,
    });
    verifyAuthorization(response);
    return response;
  };

  const Getfindday = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/finduserday",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const RewardsPoint = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/RewardsPoint", {
      userId,
    });
    verifyAuthorization(response);
    return response.data;
  };

  const CreateGroup = async (userId, groupname, grouptype, description, batch) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/creategroup",
      { userId, groupname, grouptype, description, batch }
    );
    verifyAuthorization(response);
    return response;
  };

  const Groupdetails = async (userId, searchGroup, fillterusertype, selectBatchId, id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/groupdetailsandmembers",
      { userId, searchGroup, fillterusertype, selectBatchId, id }
    );
    verifyAuthorization(response);
    return response;
  };

  const GroupList = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/Group/GetGroupList");
    verifyAuthorization(response);
    return response;
  };

  const ViewUserDetailsfromDb = async (userId, ViewusersClick) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/viewuserdetails",
      { userId, ViewusersClick }
    );
    verifyAuthorization(response);
    return response;
  };

  const removeUserFromgroup = async (userId, userid, group_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/removeuserfromgroup",
      { userId, userid, group_id }
    );

    verifyAuthorization(response);
    return response;
  };

  const GroupDelete = async (userId, groupname, groupid, grouptype) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/deletegroup",
      { userId, groupname, groupid, grouptype }
    );

    verifyAuthorization(response);
    return response;
  };

  const AssignUserTotheGroup = async (userId, SelectGroup, AssignUserDetails) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/AssignUserTotheGroup",
      { userId, SelectGroup, AssignUserDetails }
    );
    verifyAuthorization(response);
    return response;
  };

  const EditGroupDetails = async (
    userId,
    id,
    FindEditrow,
    EditGroupName,
    EditDescription,
    EditBatch
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/EditGroupDetails",
      { userId, id, FindEditrow, EditGroupName, EditDescription, EditBatch }
    );
    verifyAuthorization(response);
    return response;
  };

  const Deleteusers = async (userId, userIds, email, mobilenumber) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/Deleteusers",
      { userId, userIds, email, mobilenumber }
    );
    verifyAuthorization(response);
    return response;
  };

  const UploadFilesUserDetails = async (userId, uploaduserdata) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/UploadFilesUserDetails",
      { userId, uploaduserdata }
    );
    verifyAuthorization(response);
    return response;
  };

  //BOOKOPEDIA
  const Podcast_update = async (userId, bookId, podcast_values) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/podcasts_update",
      { userId, bookId, podcast_values }
    );
    verifyAuthorization(response);
    return response;
  };

  //Performance Check-in
  const DailyPerformance_Quiz_Questions = async (
    userId,
    Status1,
    user_id,
    monthFilter,
    learningMethodFilter,
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/dpquiz", {
      userId,
      Status1,
      user_id,
      MonthFilter: monthFilter,
      LearningMethodFilter: learningMethodFilter,
    });
    verifyAuthorization(response);
    return response;
  };

  const DailyPerformance_Quiz_Questions_Answer = async (userId, data, Calculatedcblday, User_Id, groupId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/dpquizanswer",
      {
        userId,
        data,
        Calculatedcblday,
        User_Id,
        groupId
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const DailyPerformance_Quiz_Questions_Answer_Check = async (userId, User_Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/dpquizanswercheck",
      {
        userId,
        User_Id,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const userstotalvalue = async (userId, user_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/userstotalvalue", {
      userId,
      user_id,
    });
    console.log("Response from userstotalvalue:", response);
    verifyAuthorization(response);
    return response;
  };

  const Check_user_CBLday = async (userId, userid) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/CBLday", {
      userId,
      userid,
    });
    verifyAuthorization(response);
    console.log("Check_user_CBLday response:", response.data);
    return response;
  };
  const BusinessRanking_Quiz_Questions = async (userId, Status1, User_Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/brquiz", {
      userId,
      Status1,
      User_Id,
    });
    verifyAuthorization(response);
    return response;
  };
  const BusinessRanking_Quiz_Questions_Answer = async (userId, data, User_Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/brquizanswer",
      {
        userId,
        data,
        User_Id,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const BusinessRanking_Quiz_Questions_Answer_Check = async (userId, User_Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/brquizanswercheck",
      {
        userId,
        User_Id,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const Diamond_Chart_Dashboard = async (userId, User_Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/diamondchartreport",
      {
        userId,
        User_Id,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const Diamond_Community_Dashboard = async (userId, User_Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/diamondcommunityreport",
      {
        userId,
        User_Id,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const cbl_goal_edit = async (userId, data, user_id) => {
    // console.log(user_id,'yyyyyyyyyyyyyyyyy')
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/cblgoaledit",
      {
        userId,
        data: data,
        user_id: user_id,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const cbl_goal_editeddata = async (userId, data, user_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/cblgoalediteddata",
      {
        userId,
        data: data,
        user_id: user_id,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const Ritual_stages_point = async (userId, data, user_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;

    const response = await Axios.post(
      SERVER_API_URL + "/api/user/Ritual_stages_point",
      {
        userId,
        data: data,
        user_id: data,
      }
    );

    verifyAuthorization(response);
    return response;
  };
  //BATCH MANAGMENT
  const insert_batch = async (
    userId,
    batchName,
    description,
    status,
    silver_startDate,
    silver_total_days,
    silver_endDate,
    diamond_startDate,
    diamond_total_days,
    diamond_endDate
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/insert_batch", {
      userId,
      batchName,
      description,
      status,
      silver_startDate,
      silver_total_days,
      silver_endDate,
      diamond_startDate,
      diamond_total_days,
      diamond_endDate,
    });
    verifyAuthorization(response);
    return response;
  };

  const batch_data = async (userId, text) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/batch_data", { userId, text });
    verifyAuthorization(response);
    return response;
  };

  const Edit_batch = async (userId, editedData) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/Edit_batch", {
      userId,
      editedData,
    });
    verifyAuthorization(response);
    return response;
  };

  const deleteBatch = async (userId, batchToDelete) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/delete_batch", {
      userId,
      batchToDelete,
    });
    verifyAuthorization(response);
    return response;
  };

  const Batch_Have_data = async (userId, batchToDelete) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/delete_batch_data",
      { userId, batchToDelete }
    );
    verifyAuthorization(response);
    return response;
  };
  const Dailychart = async (userId, user_id, habitName) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/dailychart",
      { userId, user_id }
    );
    return response;
  };
  const Monthlychart = async (userId, user_id, habitName) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/monthlychart",
      { userId, user_id }
    );
    return response;
  };
  const Yearlychart = async (userId, user_id, habitName) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/yearlychart",
      { userId, user_id }
    );
    return response;
  };
  const habit_chart_month_sum_insert = async (userId, user_id, habitName) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit_chart_month_sum_insert",
      { userId, user_id }
    );
    return response;
  };
  const habitchart = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/habitnew/habitcharts"
    );
    verifyAuthorization(response);
    return response.data;
  };
  // Leave MGT start

  const GetLeavecategory = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/getLeavecategory",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const Insertleaveapplication = async (
    userId,
    category,
    email,
    mobile,
    subject,
    startcblday,
    endcblday,
    content,
    fileName
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;

    const response = await Axios.post(SERVER_API_URL + "/api/Insertleaveapplication", {
      userId,
      category,
      email,
      mobile,
      subject,
      startcblday,
      endcblday,
      content,
      fileName,
    });

    verifyAuthorization(response);
    return response;
  };

  const GetdataleaveManagement = async (userId, filter, search, category) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/GetdataleaveManagement",
      { userId, filter, search, category }
    );
    verifyAuthorization(response);
    return response;
  };


  const sendToAdminHelpMessage = async (userId, email, mobile, categorys, subject, content, attachment, userid, categoryIDs, helpstatus, PrimaryId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/sendToAdminHelpMessage", { userId, email, mobile, categorys, subject, content, attachment, userid, categoryIDs, helpstatus, PrimaryId });
    verifyAuthorization(response);
    return response;
  };

  const deleteleaveapplication = async (user_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/deleteleaveapplication",
      { user_id }
    );
    verifyAuthorization(response);
    return response;
  };

  const selectLeaveApplication = async (userId, user_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/selectLeaveApplication",
      { userId, user_id }
    );
    verifyAuthorization(response);
    return response;
  };

  //DIAMOND REPORT
  const HabitReport = async (userId, search, batch_id, dailyfilter, weeklyfilter, yearlyfilter) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/HabitReport",
      { userId, search, batch_id, dailyfilter, weeklyfilter, yearlyfilter }
    );
    verifyAuthorization(response);
    return response;
  };

  const HabitReportDownload = async (userId, batch_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/HabitReportDownload",
      { userId, batch_id }
    );
    verifyAuthorization(response);
    return response;
  };

  const DiamondBasicReport = async (userId, search, batch_id, diamondscorefilter, bookfilter, attendancefilter) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/DiamondBasicReport",
      { userId, search, batch_id, diamondscorefilter, bookfilter, attendancefilter }
    );
    verifyAuthorization(response);
    return response;
  };

  const DiamondBasicReportDownload = async (userId, batch_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/DiamondBasicReportDownload",
      { userId, batch_id }
    );
    verifyAuthorization(response);
    return response;
  };

  const DiamondLearningReport = async (userId, search, batch_id, cblday) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/DiamondLearningReport",
      { userId, search, batch_id, cblday }
    );
    verifyAuthorization(response);
    return response;
  };

  const DiamondLearningReportDownload = async (userId, batch_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/DiamondLearningReportDownload",
      { userId, batch_id }
    );
    verifyAuthorization(response);
    return response;
  };


  const FindCBLday = async (userId, batch_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/CBLDAY",
      { userId, batch_id }
    );
    verifyAuthorization(response);
    return response;
  }

  const WeeklyReport = async (userId, startDate, endDate, Categories, subscriptionTypeFilter, batchValue, search) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/WeeklyReport", {
      userId, startDate, endDate, Categories, subscriptionTypeFilter, batchValue, search
    });
    verifyAuthorization(response);
    return response;
  };

  const MonthlyReport = async (userId, selectedMonth, Categories, subscriptionTypeFilter, batchValue, search) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/MonthlyReport", {
      userId, selectedMonth, Categories, subscriptionTypeFilter, batchValue, search
    });
    verifyAuthorization(response);
    return response;
  };
  const Diamond_Reward_Top = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/Diamond_Reward_Top", { userId });
    verifyAuthorization(response);
    return response;
  };
  const DiamondRewardCatogry = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/DiamondRewardCatogry", { userId });
    verifyAuthorization(response);
    return response;
  };

  const DiamondChecklistReport = async (userId, batchValue, Category, search, scoreFilter) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/DiamondChecklistReport", { userId, batchValue, Category, search, scoreFilter });
    verifyAuthorization(response);
    return response;
  };

  const ChecklistAnswer = async (userId, SelectedId, category) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/ChecklistAnswer", { userId, SelectedId, category });
    verifyAuthorization(response);
    return response;
  };

  const UserCounts = async (userId, Today, type) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/UserCounts", { userId, Today, type });
    verifyAuthorization(response);
    return response;
  };

  const DeleteBook = async (id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/DeleteBook", { id });
    verifyAuthorization(response);
    return response;
  };

  const DeleteImage = async (id, file) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/delete-image", { id, file });
    verifyAuthorization(response);
    return response;
  };

  const DeleteFile = async (id, file) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/delete-File", { id, file });
    verifyAuthorization(response);
    return response;
  };

  const EditBook = async (data) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/EditBook", { data });
    verifyAuthorization(response);
    return response;
  };

  const RenewUser = async (userId, GroupId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/RenewUser", { userId, GroupId });
    verifyAuthorization(response);
    return response;
  };

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        verifyotp,
        newPassword,
        Profiledata,
        registerOtpVerify,
        SociallinkView,
        updateSociallink,
        AllUsers,
        Addfriend,
        Removefriend,
        MyFollower,
        MyFriends,
        Statistics,
        MemberProfile,
        GetHabitsList,
        Gethabitsdata,
        InsertHabit,
        UpdateHabit,
        handleChangedeletedata,
        habit_chart,
        insert_new_habit,
        Delete_habit,
        Pastevent,
        Futurevent,
        Todayevent,
        UserDetails,
        Editdatauser,
        EditSavedata,
        passwordReset,
        MyGrouplist,
        Adddatauser,
        QuantumQuizdata,
        CoreAuditReportdata,
        NotloginReportdata,
        Csvreport,
        CharityReport,
        Watchlist,
        LoginReport,
        AlertReport,
        EventReport,
        GroupnameList,
        AddEvent,
        GetEventsList,
        DeleteSchedule,
        DeleteAllSchedule,
        updateEvent,
        Alloccupationlist,
        // - - - - Help Management - - - -
        GetdataHelpManagement,
        GetcategorylistTool,
        UpdateResponse,
        GetcategoryWithoutLogin,
        ContactInsert,
        ContactInsertWithoutLogin,
        Displayresponse,

        TopTwentyuserDetailsBaseOnRewaerdPoint,
        GetLearing,
        DeleteLearningHistory,
        InsertLearningHistory,
        CheckTotalCountOfLearning,
        CheckStatusOfLearning,
        Resourceslist,
        ResearchList,
        TemplateList,
        TechnoList,
        Quiz_Question,
        insert_Quiz,
        Check_user,
        DailyCblDownload,
        Stockopedia_post,
        Stockopedia_company,
        userstotalvalue,
        readingcomprehension,
        readingspeed,
        diamondDashboard,
        Groupuserdetails,
        Getgrouprewardschartdata,
        usersreport,
        lowAttendanceReport,
        megaChallengeAbsenteesReport,
        Ritual_Questions,
        Insert_Rituals,
        Update_stage6,
        Check_stage,
        total_points,
        ReadingSpeedTop5,
        CountofMegas,
        getMyGroupCount,
        basicQuizmarks,
        Getfindday,
        RewardsPoint,

        CreateGroup,
        Groupdetails,
        GroupList,
        ViewUserDetailsfromDb,
        removeUserFromgroup,
        GroupDelete,
        AssignUserTotheGroup,
        EditGroupDetails,
        Deleteusers,
        UploadFilesUserDetails,
        Podcast_update,
        DailyPerformance_Quiz_Questions,
        DailyPerformance_Quiz_Questions_Answer,
        DailyPerformance_Quiz_Questions_Answer_Check,
        Check_user_CBLday,
        BusinessRanking_Quiz_Questions,
        BusinessRanking_Quiz_Questions_Answer,
        BusinessRanking_Quiz_Questions_Answer_Check,
        Diamond_Community_Dashboard,
        Diamond_Chart_Dashboard,
        cbl_goal_edit,
        cbl_goal_editeddata,
        Ritual_stages_point,

        insert_batch,
        batch_data,
        Edit_batch,
        deleteBatch,
        Batch_Have_data,
        Dailychart,
        Monthlychart,
        habit_chart_month_sum_insert,
        Yearlychart,
        habitchart,
        GetBatch,
        // leave mgt
        GetLeavecategory,
        Insertleaveapplication,
        GetdataleaveManagement,
        sendToAdminHelpMessage,
        deleteleaveapplication,
        selectLeaveApplication,
        HabitReport,
        HabitReportDownload,
        DiamondBasicReport,
        DiamondBasicReportDownload,
        DiamondLearningReport,
        DiamondLearningReportDownload,
        FindCBLday,
        WeeklyReport,
        MonthlyReport,
        Diamond_Reward_Top,
        DiamondRewardCatogry,
        DiamondChecklistReport,
        ChecklistAnswer,
        UserCounts,
        DeleteBook,
        DeleteImage,
        DeleteFile,
        EditBook,
        RenewUser
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node,
};

export default JWTContext;
