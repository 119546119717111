// third-party
import { FormattedMessage } from 'react-intl';
import QuizIcon from '@mui/icons-material/Quiz';
// assets
import {IconClipboardCheck, IconDashboard, IconGraph ,IconDeviceAnalytics,IconChartArcs,IconClipboardList ,IconChartInfographic,IconKey,IconBug,IconPhoneCall,IconSquareCheck,IconBook,IconHomeQuestion,IconCalendarEvent, IconHomeCheck,IconChecklist } from '@tabler/icons';
import { Typography } from '@mui/material';
import {Lock}  from 'tabler-icons-react';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconChartArcs,
    IconClipboardList,
    IconChartInfographic,
	  IconKey,
    IconBug,
    IconPhoneCall,
	  IconSquareCheck,
    IconClipboardCheck,
    IconBook,
    IconGraph,
    IconHomeQuestion,
    IconHomeCheck,
    IconChecklist
};


const Usersid = JSON.parse(window.localStorage.getItem("users"));
const subscriptionType = Usersid ? Usersid.subscriptionType : null;
const role = Usersid?Usersid.role_id:null

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const MyActions = {
    id: 'My Actions',
    title: <FormattedMessage id="My Actions" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [] // Initialize children array
  };
  
  if (Usersid) {
    console.log({ Usersid });
  
    if (subscriptionType === "Silver") {
      // For silver subscription, add Basic Habits, Basic Quiz, and Events
      MyActions.children.push(
        {
          id: 'Quiz',
          title: <FormattedMessage id="21-Day CBL Tracker" />,
          type: 'item',
          url: '/CBLTracker',
          icon: QuizIcon,
          breadcrumbs: false
        },      
        {
          id: 'Diamond Checklist',
          title: <Typography > Diamond Checklist <Lock strokeWidth={2}></Lock> </Typography>,
          type: 'item',
          url: '/DiamondChecklist',
          icon: QuizIcon,
          breadcrumbs: false
        },
        {
          id: 'Advanced Habits',
          title: <Typography >Habit Tracker<Lock strokeWidth={2}></Lock> </Typography>,
          type: 'item',
          url: '/AdvancedHabits',
          icon: icons.IconGraph,
          breadcrumbs: false
        },
        {
          id: '365 Days Challenge',
          title: <Typography >365 Days Challenge<Lock strokeWidth={2}></Lock> </Typography>,
          type: 'item',
          url: '/dailyPerformance',
          icon: icons.IconHomeQuestion,
          breadcrumbs: false
        },
        {
          id: 'Bookopedia',
          title: <Typography> Bookopedia <Lock strokeWidth={2}></Lock> </Typography>,
          type: 'item',
          url: '/Bookopedia',
          icon: icons.IconBook,
          breadcrumbs: false
        },
        {
          id: 'Events',
          title: <FormattedMessage id="Events" />,
          type: 'item',
          url: '/Events',
          icon: icons.IconPhoneCall,
          breadcrumbs: false
        },
      );
    } else if (subscriptionType === "Diamond") {
       MyActions.children.push(
        {
          id: '365 Days Challenge',
          title: <FormattedMessage id="365 Days Challenge" />,
          type: 'item',
          url: '/dailyPerformance',
          icon: icons.IconHomeQuestion,
          breadcrumbs: false
        },
        {
          id: 'Diamond Checklist',
          title: <FormattedMessage id="Diamond Checklist"/>,
          type: 'item',
          url: '/DiamondChecklist',
          icon: IconChecklist,
          breadcrumbs: false
        },
        {
          id: 'Diamond Daily Tracker',
          title: <FormattedMessage id="Habit Tracker" />,
          type: 'item',
          url: '/AdvancedHabits',
          icon: icons.IconKey,
          breadcrumbs: false
        },
        {
          id: 'Events',
          title: <FormattedMessage id="Events" />,
          type: 'item',
          url: '/Events',
          icon: icons.IconPhoneCall,
          breadcrumbs: false
        },
		 {
          id: 'Leave Application',
          title: <FormattedMessage id="Leave Application" />,
          type: 'item',
          url: '/LeaveApplication',
          icon: QuizIcon,
          breadcrumbs: false
        },
        // {
        //   id: 'Calendar',
        //   title: <FormattedMessage id="Calendar" />,
        //   type: 'item',
        //   url: '/Calendar_Events',
        //   icon: IconCalendarEvent,
        //   breadcrumbs: false
        // },
      );
      if (role == 1) {
        MyActions.children.push({
          id: 'Bookopedia',
          title: <FormattedMessage id="Bookopedia" />,
          type: 'item',
          url: '/Bookopedia',
          icon: icons.IconBook,
          breadcrumbs: false
        });
      }
    }
  }
  
  export default MyActions;
  
  