import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import React from "react";
import useAuth from "hooks/useAuth";
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    CardActions,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';

// assets
import { IconBell } from '@tabler/icons';

// notification status options
const status = [
    {
        value: 'all',
        label: 'All Notification'
    },
    {
        value: 'new',
        label: 'New'
    },
    {
        value: 'unread',
        label: 'Unread'
    },
    {
        value: 'other',
        label: 'Other'
    }
];

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleChange = (event) => setValue(event?.target.value);

    const {
        StockAlertList,
      } = useAuth();
      const [stockListData, setstockListData] = React.useState([]);
   
 
      const Usersid = window.localStorage.getItem("users");
  const { id } = JSON.parse(Usersid);
    const stockList = async(value)=>{
        const regRes = await StockAlertList(id);
        
        setstockListData(regRes.data.Alertdata);
    }
   
      

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                        color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                            color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                        }
                    }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={()=>{handleToggle()
                        stockList()}} 
                    color="inherit"
                >
                    <IconBell stroke={1.5} size="20px" />
                </Avatar>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [matchesXs ? 5 : 0, 20]
                        }
                    }
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} style={{maxHeight: 157, overflow: 'auto'}}>
                                      {stockListData.map((row,index) => (
           <div style={{marginTop:'2px',marginBottom:'2px', border: '1px solid #ffffff',  borderRadius: '50px',padding:'3px',paddingLeft:'30px',backgroundColor:row.Alert==='low'?'#f7a6b1':'#adff99',color:'black',width:'400px'}} fullWidth>
<Grid container spacing={1} fullWidth>
<Grid item xs={12} lg={12} md={12} style={{marginTop:'8px'}}>
<Typography >{JSON.stringify(row.companyName).length>=11?JSON.stringify(row.companyName).slice(1,11)+"...":row.companyName+"  "} <b style={{marginTop:'-5px',color:'#0e0791'}}> LTP:{row.LTP+"  "}</b> <b style={{color:'green'}}> (PT:{row.PT+"  "})</b>
<b style={{color:'red'}}>(  SL:{row.SL})</b>
</Typography>

    </Grid>
    
   
    </Grid>
</div> 
        ))
          }
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
