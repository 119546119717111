import { FormattedMessage } from 'react-intl';
import { IconDashboard, IconDeviceAnalytics, IconBrandChrome, IconBrandYoutube, IconSitemap, IconTools, IconPresentation } from '@tabler/icons';

const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconBrandChrome,
    IconSitemap,
    IconTools,
    IconPresentation,
    IconBrandYoutube
};

const Resource = {
    id: "Resources",
    title: <FormattedMessage id="Resources" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        // {
        //     id: 'Learning path',
        //     title: <FormattedMessage id="Learning path" />,
        //     type: 'item',
        //     url: '/Learning',
        //     icon: icons.IconTools,

        //     breadcrumbs: false
        // },
        {
            id: 'School for Learning ',
            title: <FormattedMessage id="School for Learning" />,
            type: 'item',
            url: 'https://learn.meetmanjunath.com/',
            icon: icons.IconBrandChrome,
            breadcrumbs: false,
            external: true,
            target: true
        },
        {
            id: 'YouTube Channel ',
            title: <FormattedMessage id="YouTube Channel" />,
            type: 'item',
            url: 'https://www.youtube.com/@coachmanjunath',
            icon: icons.IconBrandYoutube,
            external: true,
            target: true
        },
           // {
        //     id: 'Reference',
        //     title: <FormattedMessage id="Reference" />,
        //     icon: icons.IconPresentation,
        //     type: 'item',
        //     url:'/Reference',
        //     breadcrumbs: false,
        // }
    ]

};

export default Resource;
