import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Popper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

// third-party
import { FormattedMessage } from "react-intl";
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import UpgradePlanCard from "./UpgradePlanCard";
import useAuth from "hooks/useAuth";
import Axios from "axios";
// assets
import { IconLogout, IconSearch, IconSettings, IconUser } from "@tabler/icons";
import useConfig from "hooks/useConfig";
import { useDispatch, useSelector } from "store";
import { API_URL, APP_URL } from "config";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = ({ fullname }) => {
  const theme = useTheme();
  const { borderRadius } = useConfig();
  const navigate = useNavigate();
  const flag = useSelector((state) => state.flag.value);
  const [sdm, setSdm] = useState(true);
  const [value, setValue] = useState("");
  const [notification, setNotification] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { logout, user } = useAuth();
  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    try {
      await logout();
      window.location.href = APP_URL + "/login";
    } catch (err) {
      console.error(err);
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  var myDate = new Date();
  var hrs = myDate.getHours();
  var greet;

  const greetFun = () => {
    if (hrs < 12) greet = "Good Morning";
    else if (hrs >= 12 && hrs < 16) greet = "Good Afternoon";
    else if (hrs >= 17 && hrs <= 23) greet = "Good Evening";
    else greet = "Good Morning";
  };
  useEffect(() => {
    greetFun();
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const [ProfileImageFilePath, setProfileImageFilePath] =
    useState("profileDefult.png");
  const Usersid = window.localStorage.getItem("users");
  const val = JSON.parse(Usersid);
  const NId = val.id;
  const userId = NId;
  const memberId = NId;
  const [Badges, setBadge] = useState();
  useEffect(() => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    Axios.post(API_URL + "/api/view_member_profile", { memberId, userId }).then(
      (response) => {
        if (response.data.data.profile_details[0].imgurl !== "") {
          setProfileImageFilePath(response.data.data.profile_details[0].imgurl);
        } else {
          setProfileImageFilePath("profileDefult.png");
        }
        setBadge(response.data.data.badge[0].marks);
      }
    );
  }, [flag]);
 
  const [getbordercolor, setbordercolor]=useState();
  const [getoutlinecolor, setoutlinecolor]=useState();
  const [getbadgevalue, setbadgevalue]=useState();
  const [getborder, setborder]=useState();
  const [getoutline, setoutline]=useState();

  useEffect(()=>{
    if(Badges ? Badges >= 500 && Badges<1000 :""){
        setbordercolor('#FFF');
        setoutlinecolor( '#1FE0A0');
        setbadgevalue('F');
        setborder('2px solid');
        setoutline('3px solid');
    }else if(Badges ? Badges >=1000 && Badges<1500 :''){
        setbordercolor('#FFF');
        setoutlinecolor( '#A01FE0');
        setbadgevalue('A');
        setborder('2px solid');
        setoutline('3px solid');
    }
    else if(Badges ? Badges >= 1500 :''){
        setbordercolor('#FFF');
        setoutlinecolor( '#E0A01F');
        setbadgevalue('w');
        setborder('2px solid');
        setoutline('3px solid');
    }
    else{
        setbordercolor('#FFF');
        setoutlinecolor( '#FFF');
        setborder('0px solid');
    }


   })

  return (
    <>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor:
            theme.palette.mode === "dark"
              ? theme.palette.dark.main
              : theme.palette.primary.light,
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.dark.main
              : theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            "& svg": {
              stroke: theme.palette.primary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            src={API_URL + "/image/" + ProfileImageFilePath}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: "8px 0 8px 8px !important",
             // borderRadius: "50%",
            // border: getborder,
           // outline: getoutline,
          // outlineColor: getoutlinecolor,
              borderColor: "white",
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={
          <IconSettings
            stroke={1.5}
            size="24px"
            color={theme.palette.primary.main}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />

      <Popper
        placement="bottom"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 14],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                {open && (
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Stack>
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                        >
                          <Typography variant="h4">Hi!, {fullname} </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                    <PerfectScrollbar
                      style={{
                        height: "100%",
                        maxHeight: "calc(100vh - 250px)",
                        overflowX: "hidden",
                      }}
                    >
                      <Box sx={{ p: 2, pt: 0 }}>
                        <List
                          component="nav"
                          sx={{
                            width: "100%",
                            maxWidth: 350,
                            minWidth: 300,
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: "10px",
                            [theme.breakpoints.down("md")]: {
                              minWidth: "100%",
                            },
                            "& .MuiListItemButton-root": {
                              mt: 0.5,
                            },
                          }}
                        >
                          <ListItemButton
                            sx={{ borderRadius: `${borderRadius}px` }}
                            selected={selectedIndex === 4}
                            onClick={handleLogout}
                          >
                            <ListItemIcon>
                              <IconLogout stroke={1.5} size="20px" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body2">
                                  <FormattedMessage id="logout" />
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </List>
                      </Box>
                    </PerfectScrollbar>
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
